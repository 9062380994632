import React from 'react'
import Layout from '../components/layout'

const ThankYou = () => (
  <Layout>
    <h1>Thank you</h1>
    <p>Your messsage has been submitted successfully!</p>
  </Layout>
)

export default ThankYou
